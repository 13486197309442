<script>
    import WesurLogo from "$lib/images/wesur_logo.svg";
</script>

<nav class="mb-5">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex flex-shrink-0 items-center">
          <img class="h-8 w-auto" src={WesurLogo} alt="WeSur">
        </div>
      </div>
    </div>
  </div>
</nav>